body {
  margin: 0px;
}

.apollos-playlist-container {
  margin: 0px;
  height: 100vh;
  min-height: 100vh;
  background-color: #d3d3d3;
}
